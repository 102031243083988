const REUSED_MAPS = {
  ACURA_MDX_2022: [
    {
      label: 'Performance',
      imageUrlPortrait: '/img/short-videos/2022-acura-mdx/portrait/performance_desktop.jpg',
      videoIdPortrait: 'i0PI3vkvDtE',
      videoDuration: '1:58',
    },
    {
      label: 'Storage',
      imageUrlPortrait: '/img/short-videos/2022-acura-mdx/portrait/storage_desktop.jpg',
      videoIdPortrait: '0ik6puZcJEA',
      videoDuration: '0:50',
    },
    {
      label: 'Fuel Economy',
      imageUrlPortrait: '/img/short-videos/2022-acura-mdx/portrait/fuel-economy_desktop.jpg',
      videoIdPortrait: 'kC_-q7s9tt4',
      videoDuration: '0:48',
    },
  ],
  CADILLAC_ESCALADE_2023: [
    {
      label: 'Performance',
      title: 'The Cadillac Escalade V Has a V Mode: Here’s What You Need to Know',
      videoIdPortrait: 'tHWw9jYV1iE',
      videoDuration: '0:38',
    },
    {
      label: 'Exterior',
      title: 'Here’s How to Tell the Cadillac Escalade V Apart From Lesser Models',
      videoIdPortrait: 'DBhBc9XBANM',
      videoDuration: '0:48',
    },
    {
      label: 'Technology',
      title: 'Everything You Need to Know About the Cadillac Escalade V’s High-Tech Interior',
      videoIdPortrait: 'oQsktTTnFJE',
      videoDuration: '0:50',
    },
  ],
  CHEVROLET_CAMARO_2023: [
    {
      label: 'Interior',
      title: 'Chevy Camaro Convertible 60-Second Interior Overview',
      videoIdPortrait: 'vPrnsGIuqBg',
      videoDuration: '0:39',
    },
    {
      label: 'Technology',
      title: 'Quick Tutorial on Operating the Chevy Camaro Convertible’s Soft Top',
      videoIdPortrait: 'biOuwV7jLMg',
      videoDuration: '0:47',
    },
    {
      label: 'Wildcard',
      title: 'Everything You Need to Know About the Chevy Camaro Model Lineup',
      videoIdPortrait: 'ASOKvRZnJug',
      videoDuration: '1:04',
    },
  ],
  CHEVROLET_CORVETTE_2020: [
    {
      label: 'Performance',
      title: 'Chevrolet Corvette Stingray: Performance Stats',
      videoIdPortrait: '_bGZO_33-vw',
      videoDuration: '0:48',
    },
    {
      label: 'Exterior',
      title: 'Chevrolet Corvette Stingray: Exterior Walkaround',
      videoIdPortrait: 'pacL3KbwfGU',
      videoDuration: '0:57',
    },
    {
      label: 'Interior',
      title: 'Chevrolet Corvette Stingray: Interior Overview',
      videoIdPortrait: 'YLmhOYzpkLU',
      videoDuration: '0:58',
    },
  ],
  CHEVROLET_SILVERADO_1500_2023: [
    {
      label: 'Performance',
      title: '2023 Chevrolet Silverado 1500 Powertrain Choices',
      videoIdPortrait: 'EmcJ5mv4oP0',
      videoDuration: '0:38',
    },
    {
      label: 'Technology',
      title: '2023 Chevrolet Silverado 1500 Tech Overview',
      videoIdPortrait: 'OOPTLSCcOOQ',
      videoDuration: '0:37',
    },
    {
      label: 'Storage',
      title: '2023 Chevrolet Silverado 1500: All About the Bed',
      videoIdPortrait: 'wzQ81bYP9Q4',
      videoDuration: '0:28',
    },
  ],
  CHEVROLET_TAHOE_2022: [
    {
      label: 'Performance',
      title: 'Chevrolet Tahoe: Engines, Towing and Fuel Economy',
      videoIdPortrait: 'KLoGK7wftLc',
      videoDuration: '0:33',
    },
    {
      label: 'Exterior',
      title: 'Chevrolet Tahoe: Z71 Package Review',
      videoIdPortrait: 'Qs2W2KztOMw',
      videoDuration: '0:36',
    },
    {
      label: 'Interior',
      title: 'Chevrolet Tahoe: Interior Overview',
      videoIdPortrait: 'MKGVVxWR4_c',
      videoDuration: '0:43',
    },
  ],
  FORD_BRONCO_2023: [
    {
      label: 'Performance',
      title: 'Ford Bronco Off-Road Capability: Everything You Need to Know',
      videoIdPortrait: 'bvr3o3nGbOw',
      videoDuration: '0:41',
      imageUrlPortrait: '/img/short-videos/2023-ford-bronco/portrait/performance.jpg',
    },
    {
      label: 'Interior',
      title: 'Ford Bronco Interior Buttons & Switches: Everything You Need to Know',
      videoIdPortrait: '72t0UegUiC4',
      videoDuration: '0:50',
      imageUrlPortrait: '/img/short-videos/2023-ford-bronco/portrait/interior.jpg',
    },
    {
      label: 'Storage',
      title: 'Know This Before Taking Your Ford Bronco Off-Road',
      videoIdPortrait: 'AUcn9kyuDKM',
      videoDuration: '0:45',
      imageUrlPortrait: '/img/short-videos/2023-ford-bronco/portrait/storage.jpg',
    },
  ],
  FORD_ESCAPE_2023: [
    {
      label: 'Exterior',
      title: '2023 Ford Escape Exterior Overview: Everything About the Refreshed Look',
      videoIdPortrait: 'CQqQ_W8z6K8',
      videoDuration: '0:28',
    },
    {
      label: 'Interior',
      title: 'Ford Escape Interior and Cargo Space Overview',
      videoIdPortrait: 'NltIYcupemI',
      videoDuration: '0:56',
    },
    {
      label: 'Technology',
      title: 'Ford Escape Tech Overview: Big Screens and Lots of Features',
      videoIdPortrait: 'Yqtx61E43U8',
      videoDuration: '0:37',
    },
  ],
  FORD_F150_2023: [
    {
      label: 'Interior',
      title: 'Ford <span class="text-nowrap">F-150:</span> Innovative Interior Features Explained',
      videoIdPortrait: 'YV05BkJNt6c',
      videoDuration: '0:51',
      imageUrlPortrait: '/img/short-videos/2023-ford-f150/portrait/interior.jpg',
    },
    {
      label: 'Technology',
      title: `Ford <span class="text-nowrap">F-150's</span> Pro Power Onboard Explained`,
      videoIdPortrait: 'mv3m2g-96jA',
      videoDuration: '0:30',
      imageUrlPortrait: '/img/short-videos/2023-ford-f150/portrait/technology.jpg',
    },
    {
      label: 'Storage',
      title: 'Ford <span class="text-nowrap">F-150</span> Bed And Tailgate: Everything You Need to Know',
      videoIdPortrait: 'Auu63i4vFb8',
      videoDuration: '0:45',
      imageUrlPortrait: '/img/short-videos/2023-ford-f150/portrait/storage.jpg',
    },
  ],
  FORD_F150_LIGHTNING_2023: [
    {
      label: 'Performance',
      title: 'Can the Ford <span class="text-nowrap">F-150</span> Lightning Really Tow?',
      videoIdPortrait: 'DQYTfO2i1fg',
      videoDuration: '0:38',
    },
    {
      label: 'Interior',
      title: 'Ford <span class="text-nowrap">F-150</span> Lightning Interior Overview: No Compromise',
      videoIdPortrait: 'tkLd504wirw',
      videoDuration: '0:51',
    },
    {
      label: 'Wildcard',
      title: `Here’s Why the Ford <span class="text-nowrap">F-150</span> Lightning Is Still a True Ford Truck`,
      videoIdPortrait: 'YGFhqkF_Jng',
      videoDuration: '0:43',
    },
  ],
  FORD_MAVERICK_2023: [
    {
      label: 'Comfort',
      title: 'Ford Maverick Innovative Interior Storage Solutions Explained',
      videoIdPortrait: 'mLdKXPSkvdU',
      videoDuration: '0:48',
      imageUrlPortrait: '/img/short-videos/2023-ford-maverick/portrait/comfort.jpg',
    },
    {
      label: 'Interior',
      title: 'Ford Maverick: Everything You Need to Know About the Interior',
      videoIdPortrait: 'btW4N61atLk',
      videoDuration: '0:37',
      imageUrlPortrait: '/img/short-videos/2023-ford-maverick/portrait/interior.jpg',
    },
    {
      label: 'Storage',
      title: 'Ford Maverick Bed: Everything You Need to Know',
      videoIdPortrait: 'M56kAzf8sVw',
      videoDuration: '0:46',
      imageUrlPortrait: '/img/short-videos/2023-ford-maverick/portrait/storage.jpg',
    },
  ],
  FORD_MUSTANG_MACH_E_2021: [
    {
      label: 'Interior',
      title: 'Ford Mustang <span class="text-nowrap">Mach-E:</span> Interior & Tech Overview',
      videoIdPortrait: '5FAt82IflJw',
      videoDuration: '0:45',
    },
    {
      label: 'Storage',
      title: 'Ford Mustang <span class="text-nowrap">Mach-E:</span> Cargo Room & Interior Space',
      videoIdPortrait: 'RdcdJxMz4Xg',
      videoDuration: '0:49',
    },
    {
      label: 'Fuel Economy',
      title: 'Ford Mustang <span class="text-nowrap">Mach-E:</span> Range & Charging',
      videoIdPortrait: 'pttPRj9iXN4',
      videoDuration: '0:43',
    },
  ],
  HONDA_ACCORD_2023: [
    {
      label: 'Interior',
      title: '2023 Honda Accord Interior Space',
      videoIdPortrait: 'nZyvKS71WeQ',
      videoDuration: '0:32',
    },
    {
      label: 'Technology',
      title: '2023 Honda Accord Tech Overview',
      videoIdPortrait: 'IT3pc0keJCY',
      videoDuration: '0:36',
    },
    {
      label: 'Wildcard',
      title: '2023 Honda Accord Hybrid Trims',
      videoIdPortrait: 'u-hIDHQrOeo',
      videoDuration: '0:40',
    },
  ],
  HONDA_CIVIC_2023: [
    {
      label: 'Comfort',
      title: 'Honda Civic Seat Comfort: Everything You Need to Know',
      videoIdPortrait: 'IyVe0yjAyXg',
      videoDuration: '0:53',
      imageUrlPortrait: '/img/short-videos/2023-honda-civic/portrait/comfort.jpg',
    },
    {
      label: 'Interior',
      title: 'Why the Honda Civic Is the Most Refined Compact Sedan',
      videoIdPortrait: 'OxX7D9MQ6Jk',
      videoDuration: '0:45',
      imageUrlPortrait: '/img/short-videos/2023-honda-civic/portrait/interior.jpg',
    },
    {
      label: 'Storage',
      title: 'Here’s What You Need to Know About Carrying Your Kids in the Honda Civic',
      videoIdPortrait: 'o6lxqp0Dom8',
      videoDuration: '0:40',
      imageUrlPortrait: '/img/short-videos/2023-honda-civic/portrait/storage.jpg',
    },
  ],
  HONDA_HRV_2024: [
    {
      label: 'Comfort',
      title:
        'This Is How the Honda <span class="text-nowrap">HR-V</span> Compares to the <span class="text-nowrap">CR-V</span>',
      videoIdPortrait: 'LO9ArJov76Y',
      videoDuration: '0:23',
    },
    {
      label: 'Interior',
      title: 'Honda <span class="text-nowrap">HR-V</span> Standard Equipment Overview',
      videoIdPortrait: 'v5f_xLO8r1w',
      videoDuration: '0:31',
    },
    {
      label: 'Storage',
      title: 'Honda <span class="text-nowrap">HR-V</span> Cargo Overview',
      videoIdPortrait: 'ZonVaqtdeAs',
      videoDuration: '0:28',
    },
  ],
  JEEP_GRAND_CHEROKEE_2023: [
    {
      label: 'Technology',
      title: '2023 Jeep Grand Cherokee Tech Overview',
      videoIdPortrait: '7j75V3Uizt0',
      videoDuration: '0:41',
    },
    {
      label: 'Value',
      title: '2023 Jeep Grand Cherokee Model Lineup',
      videoIdPortrait: 'eC1TyJu7So0',
      videoDuration: '0:38',
    },
    {
      label: 'Wildcard',
      title: '2023 Jeep Grand Cherokee 4xe: What You Should Know About the Plug-in Hybrid',
      videoIdPortrait: '47r5IJ6D854',
      videoDuration: '0:36',
    },
  ],
  KIA_SORENTO_2021: [
    {
      label: 'Performance',
      imageUrlPortrait: '/img/short-videos/2021-kia-sorento/portrait/performance_desktop.jpg',
      videoIdPortrait: 'Stt1PeZR-PY',
      videoDuration: '0:25',
    },
    {
      label: 'Storage',
      imageUrlPortrait: '/img/short-videos/2021-kia-sorento/portrait/storage_desktop.jpg',
      videoIdPortrait: '2Sa8-Er0vxY',
      videoDuration: '0:43',
    },
    {
      label: 'Fuel Economy',
      imageUrlPortrait: '/img/short-videos/2021-kia-sorento/portrait/fuel-economy_desktop.jpg',
      videoIdPortrait: 'xJK1z39v_rI',
      videoDuration: '0:56',
    },
  ],
  KIA_SPORTAGE_2023: [
    {
      label: 'Performance',
      imageUrlPortrait: '/img/short-videos/2023-kia-sportage/portrait/performance_desktop.jpg',
      videoIdPortrait: 'ta7ZV7tXjXc',
      videoDuration: '1:38',
    },
    {
      label: 'Interior',
      imageUrlPortrait: '/img/short-videos/2023-kia-sportage/portrait/interior_desktop.jpg',
      videoIdPortrait: 'nDMbKWcaYzo',
      videoDuration: '1:29',
    },
    {
      label: 'Storage',
      imageUrlPortrait: '/img/short-videos/2023-kia-sportage/portrait/storage_desktop.jpg',
      videoIdPortrait: 'DTu_IbELOgc',
      videoDuration: '0:55',
    },
  ],
  KIA_TELLURIDE_2023: [
    {
      label: 'Comfort',
      title: 'Kia Telluride: Space and Comfort',
      videoIdPortrait: 'SsvYZ_Uglxk',
      videoDuration: '1:00',
    },
    {
      label: 'Exterior',
      title: 'Kia Telluride: X-Pro Package Review',
      videoIdPortrait: 'hqfuQDyQvJY',
      videoDuration: '0:36',
    },
    {
      label: 'Interior',
      title: 'Kia Telluride: Interior Review',
      videoIdPortrait: 'q8NiYLrZ0b8',
      videoDuration: '0:50',
    },
  ],
  MAZDA_CX5_2023: [
    {
      label: 'Performance',
      title: 'Mazda <span class="text-nowrap">CX-5:</span> Engines, Performance and Fuel Economy',
      videoIdPortrait: '9slbvVVDX44',
      videoDuration: '0:41',
    },
    {
      label: 'Interior',
      title: 'Mazda <span class="text-nowrap">CX-5:</span> Is It Practical?',
      videoIdPortrait: 'vuHkCJ9vF4I',
      videoDuration: '0:39',
    },
    {
      label: 'Technology',
      title: 'Mazda <span class="text-nowrap">CX-5:</span> Interior Tech Review',
      videoIdPortrait: 'AEHn2BBLVS0',
      videoDuration: '0:32',
    },
  ],
  MAZDA_CX50_2023: [
    {
      label: 'Performance',
      imageUrlPortrait: '/img/short-videos/2023-mazda-cx50/portrait/performance_desktop.jpg',
      videoIdPortrait: 'yH5BNrXLhGA',
      videoDuration: '0:49',
    },
    {
      label: 'Interior',
      imageUrlPortrait: '/img/short-videos/2023-mazda-cx50/portrait/interior_desktop.jpg',
      videoIdPortrait: 'rk2FtPdIsM4',
      videoDuration: '1:51',
    },
    {
      label: 'Storage',
      imageUrlPortrait: '/img/short-videos/2023-mazda-cx50/portrait/storage_desktop.jpg',
      videoIdPortrait: 'NZViv0Y9zqE',
      videoDuration: '1:19',
    },
  ],
  MERCEDES_BENZ_GLS_CLASS_2022: [
    {
      label: 'Performance',
      imageUrlPortrait: '/img/short-videos/2022-mercedes-benz-gls-class/portrait/performance_desktop.jpg',
      videoIdPortrait: 'qMpej7DX9aY',
      videoDuration: '1:20',
    },
    {
      label: 'Interior',
      imageUrlPortrait: '/img/short-videos/2022-mercedes-benz-gls-class/portrait/interior_desktop.jpg',
      videoIdPortrait: '13qOI0nGyJ8',
      videoDuration: '2:05',
    },
    {
      label: 'Storage',
      imageUrlPortrait: '/img/short-videos/2022-mercedes-benz-gls-class/portrait/storage_desktop.jpg',
      videoIdPortrait: '0WLcvDamA5Y',
      videoDuration: '0:31',
    },
  ],
  PORSCHE_911_2023: [
    {
      label: 'Interior',
      title: 'Interior Configurability in the Porsche 911 Carrera GTS',
      videoIdPortrait: 'ZostqEHCllo',
      videoDuration: '0:40',
    },
    {
      label: 'Storage',
      title: 'How You Can Store Your Stuff in the Porsche 911 Carrera GTS',
      videoIdPortrait: '909-L74MsgU',
      videoDuration: '0:52',
    },
    {
      label: 'Wildcard',
      title: 'Here’s Why the Porsche 911 Carrera GTS Is the Sweet Spot of the Model Lineup',
      videoIdPortrait: '8ZOPG9YvZzA',
      videoDuration: '0:59',
    },
  ],
  RIVIAN_R1T_2022: [
    {
      label: 'Interior',
      title: 'Rivian R1T: Interior Overview',
      videoIdPortrait: 'J6ZO33TDYBE',
      videoDuration: '0:52',
    },
    {
      label: 'Storage',
      title: 'Rivian R1T: Exterior Storage',
      videoIdPortrait: 'PYkEZbMdUSI',
      videoDuration: '0:59',
    },
    {
      label: 'Fuel Economy',
      title: 'Rivian R1T: Range & Charging',
      videoIdPortrait: 'j17HJjt7Hi4',
      videoDuration: '0:43',
    },
  ],
  TESLA_MODEL_Y_2023: [
    {
      label: 'Interior',
      title: 'Tesla Model Y: Interior Overview',
      videoIdPortrait: 'qNyEwI_zqBA',
      videoDuration: '0:59',
    },
    {
      label: 'Storage',
      title: 'How Spacious Is the Tesla Model Y?',
      videoIdPortrait: 'ktMB-Xt0rKI',
      videoDuration: '0:58',
    },
    {
      label: 'Fuel Economy',
      title: 'Tesla Model Y: Range & Charging',
      videoIdPortrait: '7B3-J2aYxEo',
      videoDuration: '0:47',
    },
  ],
  TOYOTA_CAMRY_HYBRID_2023: [
    {
      label: 'Interior',
      title: 'Toyota Camry Hybrid: Interior Review',
      videoIdPortrait: '973cAK-Cm1M',
      videoDuration: '1:00',
    },
    {
      label: 'Storage',
      title: 'Toyota Camry Hybrid: Passenger and Cargo Space',
      videoIdPortrait: 'sG_MI-ZG7tw',
      videoDuration: '0:54',
    },
    {
      label: 'Fuel Economy',
      title: 'Toyota Camry Hybrid: Fuel Economy',
      videoIdPortrait: 'Sg-8Ge1aYyI',
      videoDuration: '0:55',
    },
  ],
  TOYOTA_COROLLA_CROSS_2022: [
    {
      label: 'Performance',
      imageUrlPortrait: '/img/short-videos/2022-toyota-corolla-cross/portrait/performance_desktop.jpg',
      videoIdPortrait: 'zZ0d8uwP9es',
      videoDuration: '1:27',
    },
    {
      label: 'Interior',
      imageUrlPortrait: '/img/short-videos/2022-toyota-corolla-cross/portrait/interior_desktop.jpg',
      videoIdPortrait: '-O1VOy230eg',
      videoDuration: '1:00',
    },
    {
      label: 'Storage',
      imageUrlPortrait: '/img/short-videos/2022-toyota-corolla-cross/portrait/storage_desktop.jpg',
      videoIdPortrait: 'ZeKhpA1qhuE',
      videoDuration: '0:35',
    },
  ],
  TOYOTA_COROLLA_HYBRID_2023: [
    {
      label: 'Technology',
      title: 'Toyota Goes High-Tech: All About the Toyota Corolla Hybrid’s Infotainment Suite',
      videoIdPortrait: 'HsSkPFuLDUk',
      videoDuration: '0:57',
    },
    {
      label: 'Storage',
      title: 'Toyota Corolla Hybrid: All About the Storage Solutions',
      videoIdPortrait: 'Shes2R4Ls4Y',
      videoDuration: '0:56',
    },
    {
      label: 'Wildcard',
      title: 'The Differences Between the Toyota Corolla Hybrid and the Prius',
      videoIdPortrait: 'nawu8TxFqOM',
      videoDuration: '0:47',
    },
  ],
  TOYOTA_HIGHLANDER_HYBRID_2023: [
    {
      label: 'Interior',
      title: 'Toyota Highlander Hybrid: Passenger and Cargo Space',
      videoIdPortrait: 'jP7fMjWgwis',
      videoDuration: '0:51',
    },
    {
      label: 'Storage',
      title: 'Toyota Highlander Hybrid: Interior Tech Review',
      videoIdPortrait: '8A4QRcJ6b2s',
      videoDuration: '0:35',
    },
    {
      label: 'Fuel Economy',
      title: 'Toyota Highlander Hybrid: Fuel Economy',
      videoIdPortrait: 'TxT751Ppfuk',
      videoDuration: '0:44',
    },
  ],
  TOYOTA_RAV4_HYBRID_2023: [
    {
      label: 'Performance',
      title: '2023 Toyota RAV4 Hybrid: Safety Features',
      videoIdPortrait: 'nx6TEMdr0wk',
      videoDuration: '0:36',
    },
    {
      label: 'Storage',
      title: '2023 Toyota RAV4 Hybrid: Interior Space and Storage',
      videoIdPortrait: '7jTXabXnEPk',
      videoDuration: '0:58',
    },
    {
      label: 'Fuel Economy',
      title: '2023 Toyota RAV4 Hybrid: Fuel Economy',
      videoIdPortrait: '0Kud68EzB4I',
      videoDuration: '0:45',
    },
  ],
  TOYOTA_TUNDRA_2022: [
    {
      label: 'Performance',
      imageUrlPortrait: '/img/short-videos/2022-toyota-tundra/portrait/performance_desktop.jpg',
      videoIdPortrait: '740Kv14Y-IE',
      videoDuration: '2:22',
    },
    {
      label: 'Interior',
      imageUrlPortrait: '/img/short-videos/2022-toyota-tundra/portrait/interior_desktop.jpg',
      videoIdPortrait: 'qcQk0geAzgg',
      videoDuration: '1:32',
    },
    {
      label: 'Towing',
      imageUrlPortrait: '/img/short-videos/2022-toyota-tundra/portrait/storage_desktop.jpg',
      videoIdPortrait: '5CHrT3gX9Sw',
      videoDuration: '1:39',
    },
  ],
};
export const SHORT_VIDEOS_MAP = {
  acura: {
    mdx: { '2022': REUSED_MAPS.ACURA_MDX_2022, '2023': REUSED_MAPS.ACURA_MDX_2022, '2024': REUSED_MAPS.ACURA_MDX_2022 },
  },
  cadillac: {
    escalade: {
      '2023': REUSED_MAPS.CADILLAC_ESCALADE_2023,
      '2024': REUSED_MAPS.CADILLAC_ESCALADE_2023,
    },
  },
  chevrolet: {
    camaro: {
      '2023': REUSED_MAPS.CHEVROLET_CAMARO_2023,
      '2024': REUSED_MAPS.CHEVROLET_CAMARO_2023,
    },
    colorado: {
      '2024': [
        {
          label: 'Interior',
          title: 'Chevy Colorado Interior Overview',
          videoIdPortrait: 'i9jFuafdPL0',
          videoDuration: '0:32',
        },
        {
          label: 'Storage',
          title: 'Everything You Need to Know About the Chevy Colorado’s Interior Storage',
          videoIdPortrait: 'Szgaz8fb8FI',
          videoDuration: '0:40',
        },
        {
          label: 'Wildcard',
          title: 'Chevy Colorado Truck Bed Tips & Tricks',
          videoIdPortrait: '-lvLuYzN-LY',
          videoDuration: '0:33',
        },
      ],
    },
    corvette: {
      '2020': REUSED_MAPS.CHEVROLET_CORVETTE_2020,
      '2021': REUSED_MAPS.CHEVROLET_CORVETTE_2020,
      '2022': REUSED_MAPS.CHEVROLET_CORVETTE_2020,
      '2023': REUSED_MAPS.CHEVROLET_CORVETTE_2020,
      '2024': REUSED_MAPS.CHEVROLET_CORVETTE_2020,
    },
    'silverado-1500': {
      '2023': REUSED_MAPS.CHEVROLET_SILVERADO_1500_2023,
      '2024': REUSED_MAPS.CHEVROLET_SILVERADO_1500_2023,
    },
    tahoe: {
      '2022': REUSED_MAPS.CHEVROLET_TAHOE_2022,
      '2023': REUSED_MAPS.CHEVROLET_TAHOE_2022,
      '2024': REUSED_MAPS.CHEVROLET_TAHOE_2022,
    },
  },
  dodge: {
    challenger: {
      '2023': [
        {
          label: 'Performance',
          title: '2023 Dodge Challenger Performance Overview',
          videoIdPortrait: 'rHXjFYrWZb0',
          videoDuration: '0:39',
        },
        {
          label: 'Exterior',
          title: '2023 Dodge Challenger Exterior Design Overview: Stone Cold Good Looks',
          videoIdPortrait: 'AdEgF2FnvUQ',
          videoDuration: '0:36',
        },
        {
          label: 'Interior',
          title: 'How Comfortable Is the 2023 Dodge Challenger?',
          videoIdPortrait: 'C2_01qUitEQ',
          videoDuration: '0:43',
        },
      ],
    },
    durango: {
      '2024': [
        {
          label: 'Performance',
          title: 'Dodge Durango Engines: Which One is the Best?',
          videoIdPortrait: '4k2BAf70jFc',
          videoDuration: '0:27',
        },
        {
          label: 'Comfort',
          title: 'Is The Dodge Durango a Family Car?',
          videoIdPortrait: 'BgEXFtPl7UM',
          videoDuration: '0:26',
        },
        {
          label: 'Interior',
          title: 'Dodge Durango Interior Overview',
          videoIdPortrait: 'l9Mg9VJsT9o',
          videoDuration: '0:29',
        },
      ],
    },
  },
  ford: {
    bronco: {
      '2022': [
        {
          label: 'Performance',
          imageUrlPortrait: '/img/short-videos/2022-ford-bronco/portrait/performance_desktop.jpg',
          videoIdPortrait: 'Pv3m539C3Ss',
          videoDuration: '1:00',
        },
        {
          label: 'Interior',
          imageUrlPortrait: '/img/short-videos/2022-ford-bronco/portrait/interior_desktop.jpg',
          videoIdPortrait: '_f3BJsdIWP8',
          videoDuration: '2:14',
        },
        {
          label: 'Storage',
          imageUrlPortrait: '/img/short-videos/2022-ford-bronco/portrait/storage_desktop.jpg',
          videoIdPortrait: 'd9a4eZIYI6g',
          videoDuration: '0:27',
        },
      ],
      '2023': REUSED_MAPS.FORD_BRONCO_2023,
      '2024': REUSED_MAPS.FORD_BRONCO_2023,
    },
    escape: {
      '2023': REUSED_MAPS.FORD_ESCAPE_2023,
      '2024': REUSED_MAPS.FORD_ESCAPE_2023,
    },
    'f-150': {
      '2023': REUSED_MAPS.FORD_F150_2023,
      '2024': REUSED_MAPS.FORD_F150_2023.filter(({ label }) => label !== 'Interior'),
    },
    'f-150-lightning': {
      '2023': REUSED_MAPS.FORD_F150_LIGHTNING_2023,
      '2024': REUSED_MAPS.FORD_F150_LIGHTNING_2023,
    },
    maverick: {
      '2023': REUSED_MAPS.FORD_MAVERICK_2023,
      '2024': REUSED_MAPS.FORD_MAVERICK_2023,
    },
    mustang: {
      '2024': [
        {
          label: 'Performance',
          title: 'Ford Mustang Dark Horse Performance Overview',
          videoIdPortrait: 'hQDV7TjVwTM',
          videoDuration: '0:27',
        },
        {
          label: 'Interior',
          title: 'Ford Mustang Dark Horse Interior Overview: Screen Central',
          videoIdPortrait: '8IMTMVHufDE',
          videoDuration: '0:25',
        },
        {
          label: 'Wildcard',
          title: 'Explained: What’s a Mustang Dark Horse?',
          videoIdPortrait: '9rOYm5GVX0c',
          videoDuration: '0:30',
        },
      ],
    },
    'mustang-mach-e': {
      '2021': REUSED_MAPS.FORD_MUSTANG_MACH_E_2021,
      '2022': REUSED_MAPS.FORD_MUSTANG_MACH_E_2021,
      '2023': REUSED_MAPS.FORD_MUSTANG_MACH_E_2021,
      '2024': REUSED_MAPS.FORD_MUSTANG_MACH_E_2021,
    },
  },
  honda: {
    accord: {
      '2023': REUSED_MAPS.HONDA_ACCORD_2023,
      '2024': REUSED_MAPS.HONDA_ACCORD_2023,
    },
    'hr-v': {
      '2023': [
        {
          label: 'Performance',
          imageUrlPortrait: '/img/short-videos/2023-honda-hrv/portrait/performance_desktop.jpg',
          videoIdPortrait: '2UENS1-Qo5Q',
          videoDuration: '1:54',
        },
        {
          label: 'Interior',
          imageUrlPortrait: '/img/short-videos/2023-honda-hrv/portrait/interior_desktop.jpg',
          videoIdPortrait: 'tUXdRMqC63E',
          videoDuration: '1:25',
        },
        {
          label: 'Storage',
          imageUrlPortrait: '/img/short-videos/2023-honda-hrv/portrait/storage_desktop.jpg',
          videoIdPortrait: 'nKa5Fyf_FIU',
          videoDuration: '1:05',
        },
      ],
      '2024': REUSED_MAPS.HONDA_HRV_2024,
      '2025': REUSED_MAPS.HONDA_HRV_2024,
    },
    'cr-v': {
      '2022': [
        {
          label: 'Performance',
          imageUrlPortrait: '/img/short-videos/2022-honda-crv/portrait/performance_desktop.jpg',
          videoIdPortrait: 'u9ckEzAle8s',
          videoDuration: '1:02',
        },
        {
          label: 'Interior',
          imageUrlPortrait: '/img/short-videos/2022-honda-crv/portrait/interior_desktop.jpg',
          videoIdPortrait: 'GfECYXqYq5U',
          videoDuration: '1:59',
        },
        {
          label: 'Storage',
          imageUrlPortrait: '/img/short-videos/2022-honda-crv/portrait/storage_desktop.jpg',
          videoIdPortrait: 'aOyTqVSbFXs',
          videoDuration: '1:14',
        },
      ],
      '2023': [
        {
          label: 'Performance',
          videoIdPortrait: 'Fs8yHx5819I',
          videoDuration: '0:50',
          imageUrlPortrait: '/img/short-videos/2023-honda-crv/portrait/performance.jpg',
        },
        {
          label: 'Interior',
          videoIdPortrait: '9uFkdpIl7a4',
          videoDuration: '1:35',
          imageUrlPortrait: '/img/short-videos/2023-honda-crv/portrait/interior.jpg',
        },
        {
          label: 'Storage',
          videoIdPortrait: '5kzJGqSxwcY',
          videoDuration: '1:13',
          imageUrlPortrait: '/img/short-videos/2023-honda-crv/portrait/storage.jpg',
        },
      ],
    },
    civic: {
      '2023': REUSED_MAPS.HONDA_CIVIC_2023,
      '2024': REUSED_MAPS.HONDA_CIVIC_2023,
    },
  },
  hyundai: {
    venue: {
      '2024': [
        {
          label: 'Comfort',
          title: 'Hyundai Venue: Affordable, But at a Cost',
          videoIdPortrait: 'EfD2PetBwQA',
          videoDuration: '0:36',
        },
        {
          label: 'Interior',
          title: 'Here’s Why the Hyundai Venue’s Small Size Is a Big Advantage',
          videoIdPortrait: 'w_vzmKNhlIQ',
          videoDuration: '0:32',
        },
        {
          label: 'Value',
          title: 'Hyundai Venue: It’s Cheap, But It Ain’t Bad',
          videoIdPortrait: 'GUNDb66udUg',
          videoDuration: '0:21',
        },
      ],
    },
  },
  jeep: {
    'grand-cherokee': {
      '2023': REUSED_MAPS.JEEP_GRAND_CHEROKEE_2023,
      '2024': REUSED_MAPS.JEEP_GRAND_CHEROKEE_2023.filter(({ label }) => label !== 'Value'),
    },
    wrangler: {
      '2023': [
        {
          label: 'Exterior',
          title: 'Jeep Wrangler Rubicon 4xe Exterior Walk-Around',
          videoIdPortrait: 'QV-mHVpa9tU',
          videoDuration: '0:45',
        },
        {
          label: 'Interior',
          title: 'Jeep Wrangler Rubicon 4xe Interior Overview',
          videoIdPortrait: 'XSIWJ6sD8po',
          videoDuration: '0:59',
        },
        {
          label: 'Fuel Economy',
          title: 'Jeep Wrangler Rubicon 4xe: How Is the Plug-in Hybrid’s Fuel Economy?',
          videoIdPortrait: 'VmqytKqzqDk',
          videoDuration: '0:38',
        },
      ],
    },
  },
  kia: {
    sorento: {
      '2021': REUSED_MAPS.KIA_SORENTO_2021,
      '2022': REUSED_MAPS.KIA_SORENTO_2021,
      '2023': REUSED_MAPS.KIA_SORENTO_2021,
      '2024': REUSED_MAPS.KIA_SORENTO_2021,
    },
    sportage: {
      '2023': REUSED_MAPS.KIA_SPORTAGE_2023,
      '2024': REUSED_MAPS.KIA_SPORTAGE_2023,
    },
    stinger: {
      '2023': [
        {
          label: 'Performance',
          title: '2023 Kia Stinger Performance Overview',
          videoIdPortrait: 'YQxoQyXjtek',
          videoDuration: '0:44',
        },
        {
          label: 'Exterior',
          title: '2023 Kia Stinger Design Overview: Stylish',
          videoIdPortrait: 'I6QNObGxP2g',
          videoDuration: '0:48',
        },
        {
          label: 'Storage',
          title: '2023 Kia Stinger Hatchback & Cargo Space',
          videoIdPortrait: 'S3cmstvdDoE',
          videoDuration: '0:39',
        },
      ],
    },
    telluride: {
      '2023': REUSED_MAPS.KIA_TELLURIDE_2023,
      '2024': REUSED_MAPS.KIA_TELLURIDE_2023,
    },
  },
  'land-rover': {
    'range-rover': {
      '2024': [
        {
          label: 'Comfort',
          title: 'Here’s How Spacious a Range Rover Is',
          videoIdPortrait: 'YRRHSCGQzik',
          videoDuration: '0:26',
        },
        {
          label: 'Technology',
          title: 'Land Rover Range Rover Infotainment Overview',
          videoIdPortrait: 'eEVGf6f3_bg',
          videoDuration: '0:23',
        },
        {
          label: 'Value',
          title: 'Here’s Why the Land Rover Range Rover is Worth the Money',
          videoIdPortrait: 'CHnliJcTJCw',
          videoDuration: '0:58',
        },
      ],
    },
  },
  mazda: {
    'cx-5': {
      '2023': REUSED_MAPS.MAZDA_CX5_2023,
      '2024': REUSED_MAPS.MAZDA_CX5_2023,
    },
    'cx-50': {
      '2023': REUSED_MAPS.MAZDA_CX50_2023,
      '2024': REUSED_MAPS.MAZDA_CX50_2023,
    },
    'mx-5-miata': {
      '2024': [
        {
          label: 'Interior',
          title: 'Mazda <span class="text-nowrap">MX-5:</span> Miata: How’s the Interior Space?',
          videoIdPortrait: 'meA-gvQl9Tk',
          videoDuration: '0:33',
        },
        {
          label: 'Technology',
          title: 'Mazda <span class="text-nowrap">MX-5:</span> Miata Tech Experience Overview',
          videoIdPortrait: 'Cdv0U15ikhE',
          videoDuration: '0:27',
        },
        {
          label: 'Wildcard',
          title:
            'Mazda <span class="text-nowrap">MX-5:</span> Miata: Hardtop vs. Soft Top | Which One Is Right For You?',
          videoIdPortrait: 'ey5Yf8I2Zv8',
          videoDuration: '0:43',
        },
      ],
    },
  },
  'mercedes-benz': {
    'gls-class': { '2022': REUSED_MAPS.MERCEDES_BENZ_GLS_CLASS_2022, '2023': REUSED_MAPS.MERCEDES_BENZ_GLS_CLASS_2022 },
  },
  polestar: {
    '3': {
      '2025': [
        {
          label: 'Comfort',
          title: 'Polestar 3: How Big Is It? ',
          videoIdPortrait: 'pkKQm3DGhxA',
          videoDuration: '0:30',
        },
        {
          label: 'Interior',
          title: 'Polestar 3: How’s the Interior?',
          videoIdPortrait: 'iw6aRMlX1pE',
          videoDuration: '0:45',
        },
        {
          label: 'Technology',
          title: 'Polestar 3 Tech Overview',
          videoIdPortrait: 'RJBhXLyygSM',
          videoDuration: '0:50',
        },
        {
          label: 'Storage',
          title: 'Polestar 3: How Much Can It Hold?',
          videoIdPortrait: 'Kf8T0jcOBUY',
          videoDuration: '0:37',
        },
        {
          label: 'Wildcard',
          title: 'Polestar 3: What Is It?',
          videoIdPortrait: '2U-VkFpUvtg',
          videoDuration: '0:53',
        },
      ],
    },
  },
  porsche: {
    '911': {
      '2023': REUSED_MAPS.PORSCHE_911_2023,
      '2024': REUSED_MAPS.PORSCHE_911_2023,
    },
  },
  rivian: {
    r1t: {
      '2022': REUSED_MAPS.RIVIAN_R1T_2022,
      '2023': REUSED_MAPS.RIVIAN_R1T_2022,
      '2024': REUSED_MAPS.RIVIAN_R1T_2022,
    },
  },
  tesla: {
    'model-3': {
      '2023': [
        {
          label: 'Technology',
          title: 'Tesla Model 3 Infotainment Overview',
          videoIdPortrait: 'aNQ_IAUuO2o',
          videoDuration: '0:55',
        },
        {
          label: 'Fuel Economy',
          title: 'Planning a Road Trip in the Tesla Model 3',
          videoIdPortrait: '8tHXVKb0XUE',
          videoDuration: '0:38',
        },
        {
          label: 'Wildcard',
          title: 'Tesla Model 3: Everything You Need to Know About the Convenient Buying Process',
          videoIdPortrait: 'eLYrGnzSX2o',
          videoDuration: '0:34',
        },
      ],
    },
    'model-y': {
      '2023': REUSED_MAPS.TESLA_MODEL_Y_2023,
      '2024': REUSED_MAPS.TESLA_MODEL_Y_2023,
    },
  },
  toyota: {
    '4runner': {
      '2024': [
        {
          label: 'Performance',
          title: 'Toyota 4Runner: How’s the Engine?',
          videoIdPortrait: '_YOu0gDel94',
          videoDuration: '0:18',
        },
        {
          label: 'Interior',
          title: 'Toyota 4Runner Interior Overview',
          videoIdPortrait: 'uQ5p7NKnGDg',
          videoDuration: '0:50',
        },
        {
          label: 'Wildcard',
          title: 'Toyota 4Runner Off-Road Overview',
          videoIdPortrait: 'DwNOApf7xKw',
          videoDuration: '0:26',
        },
      ],
    },
    'corolla-cross': {
      '2022': REUSED_MAPS.TOYOTA_COROLLA_CROSS_2022,
      '2023': REUSED_MAPS.TOYOTA_COROLLA_CROSS_2022.filter(({ label }) => label !== 'Interior'),
      '2024': REUSED_MAPS.TOYOTA_COROLLA_CROSS_2022.filter(({ label }) => label !== 'Interior'),
    },
    'camry-hybrid': {
      '2023': REUSED_MAPS.TOYOTA_CAMRY_HYBRID_2023,
      '2024': REUSED_MAPS.TOYOTA_CAMRY_HYBRID_2023,
    },
    'corolla-hybrid': {
      '2023': REUSED_MAPS.TOYOTA_COROLLA_HYBRID_2023,
      '2024': REUSED_MAPS.TOYOTA_COROLLA_HYBRID_2023,
    },
    'grand-highlander': {
      '2024': [
        {
          label: 'Interior',
          title: 'Toyota Grand Highlander Interior Overview',
          videoIdPortrait: 'AeDhZLKaZlg',
          videoDuration: '0:24',
        },
        {
          label: 'Storage',
          title: 'Toyota Grand Highlander: How Spacious is it?',
          videoIdPortrait: 'z7iN8HLr3fI',
          videoDuration: '0:34',
        },
        {
          label: 'Fuel Economy',
          title: 'Toyota Grand Highlander: What’s Hybrid Max?',
          videoIdPortrait: 'VTvvUmtaSNM',
          videoDuration: '0:34',
        },
      ],
    },
    'highlander-hybrid': {
      '2023': REUSED_MAPS.TOYOTA_HIGHLANDER_HYBRID_2023,
      '2024': REUSED_MAPS.TOYOTA_HIGHLANDER_HYBRID_2023,
    },
    rav4: {
      '2023': [
        {
          label: 'Interior',
          title: 'The Toyota RAV4’s Spacious Interior',
          videoIdPortrait: '7jTXabXnEPk',
          videoDuration: '0:58',
        },
        {
          label: 'Fuel Economy',
          title: 'Toyota RAV4 Hybrid Fuel Economy',
          videoIdPortrait: '0Kud68EzB4I',
          videoDuration: '0:45',
        },
        {
          label: 'Safety',
          title: 'Toyota RAV4 Safety Features',
          videoIdPortrait: 'nx6TEMdr0wk',
          videoDuration: '0:36',
        },
      ],
    },
    'rav4-hybrid': {
      '2023': REUSED_MAPS.TOYOTA_RAV4_HYBRID_2023,
      '2024': REUSED_MAPS.TOYOTA_RAV4_HYBRID_2023,
    },
    sienna: {
      '2024': [
        {
          label: 'Comfort',
          title: 'Here’s Why the Toyota Sienna Is Better Than an SUV',
          videoIdPortrait: 'UiP0Ax9J9ls',
          videoDuration: '0:36',
        },
        {
          label: 'Storage',
          title: 'Toyota Sienna Storage Space Overview',
          videoIdPortrait: 'bFAjorFaIa8',
          videoDuration: '0:33',
        },
        {
          label: 'Wildcard',
          title: 'Toyota Sienna: Does It Baby? | Car Seat & Safety Overview',
          videoIdPortrait: 'AAjYTRYIE4s',
          videoDuration: '0:37',
        },
      ],
    },
    tundra: {
      '2022': REUSED_MAPS.TOYOTA_TUNDRA_2022,
      '2023': REUSED_MAPS.TOYOTA_TUNDRA_2022,
      '2024': REUSED_MAPS.TOYOTA_TUNDRA_2022,
    },
  },
};
