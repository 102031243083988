import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

export function useScrollArrows(containerRef) {
  const [arrows, setArrows] = useState({ left: false, right: false });

  const checkArrows = useCallback(
    debounce(() => {
      const carousel = containerRef?.current;

      setArrows({
        left: !!carousel?.scrollLeft,
        right: carousel?.scrollWidth - carousel?.clientWidth - carousel?.scrollLeft > 1,
      });
    }, 50),
    []
  );

  useEffect(() => {
    const container = containerRef?.current;
    container?.addEventListener('scroll', checkArrows);

    checkArrows();

    return () => container?.removeEventListener('scroll', checkArrows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [arrows, checkArrows];
}
